import logo from './logo.png';
import React, { useState, useEffect } from 'react';
import { FORM_URL } from './config';

function App() {
  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
    document.documentElement.setAttribute('data-theme', theme);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://usebasin.com/basin.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div className="static" data-theme="cupcake">
      <div className="flex flex-col lg:flex-row items-center min-h-screen">
        <div className="shadow-2xl mx-auto rounded-lg w-full xs:w-1/2 lg:w-4/5">
          <div className="flex flex-col sm:flex-row">
            <div className="flex flex-col items-center justify-center w-full xs:w-1/2">
              <a href="https://www.egautomativeperformance.com">
                <img src={logo} className="App-logo w-60 mt-6 sm:w-96 sm:mt-0" alt="EG Automotive Logo" />
              </a>
            </div>
            <div class="container" className="flex flex-col items-center justify-center w-full xs:w-1/2">
              <form action={FORM_URL} method="POST" className="w-3/4 mt-6 mb-6" enctype="multipart/form-data" data-basin-form data-basin-success-action='render'>
                <div>
                  <h1 className="text-2xl font-bold mb-3 text-center">Get in touch</h1>
                  <p>Need some Automotive work done? Let's do it</p>
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Name</span>
                  </label>
                  <input type="text" className="input input-bordered" required />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Email</span>
                  </label>
                  <input type="email" name="email" id="email" className="input input-bordered" required />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Phone Number</span>
                  </label>
                  <input type="tel" name="phone" id="phone" className="input input-bordered" />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Message</span>
                  </label>
                  <textarea name="message" id="message" className="textarea textarea-bordered"></textarea>
                </div>
                <div className="form-control mt-6">
                  <button type="submit" value="Submit" className="btn">Submit</button>
                </div>
              </form>
              <div class="w-form-done" style={{ display: 'none', marginBottom: '20px' }}>Thank you for the message we'll get in touch soon! 🚀🚀🚀</div>
              <div class="w-form-fail" style={{ display: 'none', marginBottom: '20px' }}>Oops, something went wrong, refresh and try again</div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed top-0 right-4">
        <label className="swap swap-rotate mt-4">

          {/* this hidden checkbox controls the state */}
          <input type="checkbox" className="theme-controller" value="synthwave" onChange={toggleTheme} />

          {/* sun icon */}
          <svg className="swap-on fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" /></svg>

          {/* moon icon */}
          <svg className="swap-off fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" /></svg>

        </label>
      </div>
    </div>
  );
}

export default App;
